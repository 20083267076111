.note {
  display: flex;
  justify-content: center;
  grid-gap: 1rem;
  font-weight: 700;
  color: var(--colour-red-5);
}

.icon {
  flex-shrink: 0;
  stroke: var(--colour-red-5);
}

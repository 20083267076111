.modal {
  .text {
    max-height: 210px;
    overflow-y: auto;
    padding: 1rem;
    border: 1px solid var(--colour-neutral-grey-3);
    margin-bottom: 2rem;
  }

  .divider {
    margin: 1.5rem 0;
    background-color: var(--colour-neutral-grey-3);
  }

  .divider1 {
    margin-top: 0;
  }

  .infoSection {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .info {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  .signWrapper {
    display: flex;
    gap: 0.5rem;
    margin-bottom: 1.5rem;
  }

  .label {
    margin-bottom: 0.25rem;
  }

  .saveButton {
    border-color: #1f2c5f;
    background-color: #1f2c5f;
  }
}

.signature {
  position: relative;
  display: flex;
  flex-grow: 1;
  background-color: var(--colour-neutral-grey-3);
  margin-bottom: 1rem;
}

.closeButton {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  background-color: transparent;
  border: none;

  &:hover {
    color: inherit;
    background-color: transparent;

    svg {
      stroke: none;
    }
  }
}
.actions {
  display: flex;
  gap: 0.5em;
  justify-content: flex-end;
}

.dot {
  :global(.ant-badge-dot) {
    width: 8px;
    height: 8px;
  }
}

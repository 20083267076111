.root {
  display: flex;
  position: relative;
  flex-direction: column;
  pointer-events: none;
  opacity: 0;
  height: calc(100vh - 4em);
  background-color: white;
  transition: 0.3s opacity, 0.3s transform;
  transform: translateX(-50%);
}

.formInner {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  width: 100%;
  grid-gap: 0.5rem;
  padding: 0.25rem 0.5rem;
  min-height: 3.125rem;
  border-radius: 8px;
  background-color: #f0f0f0;
}

.formInnerTransparent {
  background-color: transparent;
}

.root .formInner .input {
  padding: 0.5rem 1rem;
  outline: none;
  box-shadow: none;
  background-color: transparent;
  border: 0;
}

.actions {
  display: flex;
  align-items: center;
  grid-gap: 0.5rem;
}

.iconWrapper {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 40px;
  height: 40px;
  color: var(--secondary-darkblue-color);
  background-color: #ffffff;
  border-radius: 50%;

  &:hover {
    color: #ffffff;
    background-color: var(--colour-brand-5);
  }
}

.plusButton {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 3.625rem;
  height: 3.625rem;
  padding: 0.5rem;
  color: var(--colour-brand-5);
  border: 1px solid var(--colour-neutral-grey-5);
}

.plusButton svg {
  stroke: var(--colour-brand-5);
}

.actionButton {
  padding: 0;
  border: 0;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  background-color: transparent;
}

.actionButton:hover {
  border: 0;
  background-color: transparent;
}

.actionButton:hover svg {
  stroke: transparent;
}

.actionButton:disabled {
  opacity: 0.5;
}

.icon {
  fill: currentColor;
}

.filesWrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  grid-gap: 0.5rem;
  max-width: 580px;
  padding: 1rem 0;
}

.root form.withAttachFiles {
  background-color: var(--colour-neutral-grey-2);
}

.filesWrap {
  display: flex;
  grid-gap: 0.5rem;
  padding: 1rem;
  background-color: var(--colour-neutral-grey-4);
  border-radius: 4px;
}

.filesWrapColumn {
  flex-direction: column;
  align-items: flex-start;
}

.attachFilesHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid var(--colour-neutral-grey-5);
}

.attachFilesHeader .attachFilesClearAll {
  border: 0;
  background-color: transparent;
}

.attachFilesHeader .attachFilesClearAll svg {
  stroke: transparent;
}

.attachFilesHeader .attachFilesClearAll:hover {
  color: var(--colour-red-5);
}

.attachFilesHeader .attachFilesTitle {
  font-size: 0.875rem;
  margin: 0;
}
